/** @jsx jsx */
import { jsx } from "theme-ui"
import { CpdForm, transformer, validator } from "./cpd-form"

export const ContactForm = () => {
  const contactFormConfig = [
    { required: true, name: 'name', label: 'Name' },
    {
      required: true,
      name: 'email',
      label: 'Email',
      type: 'email',
      validate: validator.email,
      transform: transformer.email
    },
    {
      name: 'reg',
      label: 'Car registration',
      validate: validator.regNo,
      transform: transformer.regNo,
      localStorageValue: 'vehicle-data.reg'
    },
    {
      required: true,
      name: 'phone',
      label: 'Contact number',
      placeholder: '+44',
      validate: validator.phone,
      transform: transformer.phone
    },
    {
      required: true,
      name: 'type',
      type: 'select',
      options: ['Ask a question', 'Buy a policy', 'Make a claim', 'Found an error'],
      label: 'Enquiry type'
    },
    { required: true, name: 'message', type: 'textarea', label: 'Enquiry details'}
  ]

  return <section className="contact-form-section">
    <CpdForm
      title="Get in touch"
      name="contact"
      action="/thanks"
      fields={contactFormConfig}
      netlify
    />
  </section>
}
