/** @jsx jsx */
import { jsx } from "theme-ui"
import { LiveChatButton } from "./live-chat-button"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
query {
  content: file(name: { eq: "contact-section" }) {
    props: childMarkdownRemark {
      html
      bits: frontmatter {
        title
        live
        hours
        call
        find
      }
    }
  }
}
`

export const ContactSection = ({ isContactPage = true }) => {
  const { content: { props: { html, bits: pageData } } } = useStaticQuery(query)

  const className = `contact-us-section${isContactPage ? '' : ' about-us'}`

  return <section className={className}>
    {isContactPage ? <h1>{pageData.title}</h1> : <h2>{pageData.title}</h2>}
    <div
      className="description"
      dangerouslySetInnerHTML={{ __html: html }}
    />
    <div className="live">
      <h3>Live chat: speak to an agent now</h3>
      {pageData.live}
      <div>
        <LiveChatButton label="Open a chat" />
      </div>
    </div>
    <div className="contact-info">
      <div className="hours">
        <h3>Office hours</h3>
        <ul>
          {pageData.hours.map((day, i) => <li key={`hours-${i}`}>{day}</li>)}
        </ul>
      </div>
      <div className="call">
        <h3>Call us</h3>
        <a href={`tel:${pageData.call}`}>
          {pageData.call}
        </a>
      </div>
      <div className="find">
        <h3>Find us</h3>
        <ul>
          {
            pageData.find.map((line, i) =>
              <li key={`find-${i}`}>
                <a target="_blank" rel="noreferrer"
                   href={`https://www.google.com/maps/place/Car+Protect+Direct/@53.385138,-1.4292111,17z/data=!3m1!4b1!4m5!3m4!1s0x487979af0d576f59:0x564ef5755e1ddb51!8m2!3d53.385138!4d-1.4270224`}>{line}</a>
              </li>)
          }
        </ul>
      </div>
    </div>
  </section>
}
