/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ContactSection } from "../components/contact-section"
import { ContactForm } from "../components/contact-form"

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Contact = ({ data }) => {
  const { site } = data // data.markdownRemark holds your post data

  return (
    <Layout className="contact-page">
      <Seo
        title="Contact us"
        description={"Contact us " + site.siteMetadata.title}
      />
      {/*<Breadcrumb title={pageData.title}/>*/}
      <div className="contact">
        <div className="contact-content">
          <ContactSection />
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
